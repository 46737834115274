import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpDialogComponent implements OnInit {

  explanation: any = {};

  constructor(private modal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this.modal.close();
  }

}
