import { NgModule } from '@angular/core';
import { LocalizedDatePipe } from './localized-date.pipe';

@NgModule({
    imports: [],
    declarations: [LocalizedDatePipe],
    exports:   [LocalizedDatePipe]
})
export class SharedPipesModule {

    static forRoot() {
      return {
          ngModule: SharedPipesModule,
          providers: [],
      };
   }
}
