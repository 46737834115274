<div class="login-page" [@routerTransition]>
    <div class="pos-f-t fixed-top header topbanner">
      <span class="logo-image logo pull-left"></span>
    </div>
    <div class="row">
      <div class="col-md-4 col-md-offset-4 container">
        <form class="form" role="form" (ngSubmit)="onSubmit(f)" #f="ngForm">
          <div ngModelGroup="credentials">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group addon">
                  <input type="text" class="form-control login-input" placeholder="gebruiker" autofocus ngModel name="email" (change)="onChangeCredentials()">
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group addon">
                  <input type="password" class="form-control login-input" ngModel name="password" placeholder="wachtwoord" (change)="onChangeCredentials()">
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary login-button button submit-button">Login</button>
          <div class="form-group">
            <a class="link" href="/forgot-password">Gebruikersnaam of wachtwoord vergeten</a>
          </div>
        </form>
        <div class="alert alert-danger" [hidden]="!loginError">De gebruikersnaam of wachtwoord is onjuist. Probeer het opnieuw.</div>
        <div class="alert alert-danger" [hidden]="!serverError">Er is een storing. Probeer het later opnieuw.</div>
        <div class="alert alert-warning" [hidden]="!isSessionExpired">Uw sessie is verlopen.</div>
      </div>
      <div class="col-md-3 copyright-notice">
        <label>Copyright 2023</label>
      </div>
    </div>
  </div>
  
  
  
  
  
