import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

export class User {
  constructor(
    public email: string,
    public password: string) { }
}

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
  readonly OAUTH_URL = '/oauth/token';
  readonly LOGIN_URL = '/login';

  readonly minimumStrengthLevel = 2;

  readonly headers = new HttpHeaders()
              .append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
              .append('authorization', 'Basic2'
        + btoa('portal_api' + ':' + 'portal_client_secret_top'));

  public constructor(
    private router: Router,
    private httpClient: HttpClient
  ) {}

  public authenticate(credentials: any) {
    this.clearSessionStorage();

    let body = new HttpParams();
    body = body.set('username', credentials.email);
    body = body.set('password', credentials.password);
    body = body.set('grant_type', 'password');
    body = body.set('scope', 'read write');
    body = body.set('client_id', 'portal_api');
    body = body.set('client_secret', 'portal_client_secret_top');

    return this.httpClient.post(this.OAUTH_URL, body, {headers: this.headers})
       .pipe(map((data: any) => {
          sessionStorage.setItem('access_token', data['access_token']);
          sessionStorage.setItem('token_object', JSON.stringify(data));
        }),
        catchError(err => this.handleError(err))
        );
  }

  public setPassword(userId: number, oldPassword: string, newPassword: string) {
    return this.httpClient.put('api/users/changePassword/' + userId, {
            oldPassword: oldPassword,
            newPassword: newPassword
          }
        ).pipe(catchError(this.handleError));
  }

  // an action by an admin
  public generateResetPasswordToken(userId: number) {
    return this.httpClient.put(
      'api/users/generateResetPasswordToken/' + userId, {}
    ).pipe(catchError(this.handleError));
  }

  // a request by the user
  public requestResetPassword(email: string) {
    return this.httpClient.post(
      'api/login/requestResetPassword', {email: email}
    ).pipe(catchError(this.handleError));
  }

  public resetPassword(token: string, password: string) {
    return this.httpClient.post(
      'api/login/resetPassword/' + token, {oldPassword: '', newPassword: password}
    ).pipe(catchError(this.handleError));
  }

  public getCurrentUser() {
    return this.httpClient.get('/api/users/currentUser')
      .pipe(map((user: any) => {
          this.setUserInSessionStorage(user);
          return user;
        }),
        catchError(err => this.handleError(err))
        );
  }

  updateUser(user: any) {
    if (user.newPassword === null || user.newPassword === undefined || user.newPassword === '' ) {
      delete user.newPassword;
    }
    return this.httpClient.put('/api/users/' + user.id, user).pipe(catchError(this.handleError));
  }

  refreshToken(refresh_token: string) {
    let body = new HttpParams();
    body = body.set('grant_type', 'refresh_token');
    body = body.set('scope', 'read write');
    body = body.set('client_id', 'portal_api');
    body = body.set('client_secret', 'portal_client_secret_top');
    body = body.set('refresh_token', refresh_token);

    return this.httpClient.post(this.OAUTH_URL, body, {headers: this.headers})
      .pipe(map((data: any) => {
          sessionStorage.setItem('access_token', data['access_token']);
          sessionStorage.setItem('token_object', JSON.stringify(data));
        }),
        catchError(err => this.handleError(err))
        );
  }

  public logout(): void {
    //this.httpClient.post('oauth/logout', {}).subscribe(() => {}, error => {});
    this.clearSessionStorage();
    this.router.navigate([this.LOGIN_URL]);
  }

  private clearSessionStorage(): void {
    sessionStorage.removeItem('loginToken');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('siteId');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('endDate');
  }

  hasPrivilege(privilegeId: number) {
    const user = this.getUserFromSessionStorage();
   // console.log(user);
    let result = false;
    if (user === null ) {
      return false;
    } else {
      for (const privilege of user.role.privileges) {
        if (privilege.id === privilegeId) {
          result = true;
          }
        }
      }
    return result;
  }

  public isLoggedIn(): boolean {
    return (sessionStorage.getItem('access_token') !== null && this.getUserFromSessionStorage() !== null);
  }

  public getUserId() {
    const user = this.getUserFromSessionStorage();
    return user.id;
  }

  public setUserInSessionStorage(user) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
  }

  public getUserFromSessionStorage() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  private handleError(error: any) {
    console.log(error);
    return throwError(error);
  }

  public isStrongPassword(passwordStrength: number) {
    return passwordStrength >= this.minimumStrengthLevel;
  }
}
