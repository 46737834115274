import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RoutingStateService } from './services/routing-state.service';
import { HTTPStatusService } from './services/httpstatus.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    true = true;
    HTTPActivity: boolean;

    constructor(private titleService: Title,
        routingState: RoutingStateService,
        private httpStatus: HTTPStatusService) {

        routingState.loadRouting();
        this.HTTPActivity = false;
        setTimeout(() => {
            this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
                this.HTTPActivity = status;
            });
        }, 100);
    }

    ngOnInit() {
    }

    applyBrand(style: string) {
        const dom: any = document.querySelector('body');
        dom.classList.toggle(style);
        this.setTitle("Het nieuwe Wonen");
    }

    setTitle(newTitle: string) {
        this.titleService.setTitle(newTitle);
    }
}
