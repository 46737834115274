import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './app-routing-guard';
import { AuthenticationService } from './services/authentication.service';
import {} from "./modules/forgot-password/forgot-password.module"

const routes: Routes = [
  { path: '', 
    loadChildren: () => import('./modules/layout/layout.module').then(m => m.LayoutModule), 
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent }, 
  { path: 'reset-password', loadChildren: () => import( "./modules/reset-password/reset-password.module").then (m => m.ResetPasswordModule)},
  { path: 'forgot-password', loadChildren: () => import("./modules/forgot-password/forgot-password.module").then(m => m.ForgotPasswordModule)},
  { path: 'not-found', loadChildren :() => import('./components/not-found/not-found.module').then(m => m.NotFoundRoutingModule)},
  { path: '**', redirectTo: 'not-found' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuard,
    AuthenticationService
  ]
})
export class AppRoutingModule { }
