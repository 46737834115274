import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuestionComponent} from './question.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [QuestionComponent],
  exports: [QuestionComponent],
  imports: [
    CommonModule,
    NgbModule
  ]
})
export class QuestionModule { }
