import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { routerTransition } from '../../router.animations';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Navigation } from '../../domain/navigation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [routerTransition()]
})
export class LoginComponent implements OnInit {

  public loginError = false;
  public serverError = false;
  public isSessionExpired = false;
  returnUrl: string;

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}


  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || Navigation.landingPage;
  }

  navigateToApplication() {
    this.router.navigate([this.returnUrl]);
  }

  onSubmit(form: NgForm) {
    this.serverError = false;
    if (form && form.value.credentials.email && form.value.credentials.password) {
      this.checkCredentials(form.value.credentials);
    }
  }

  onChangeCredentials() {
    this.loginError = false;
  }

  private handleSuccessfulAuthentication() {
    this.authenticationService.getCurrentUser().subscribe(
      userId => {
        this.navigateToApplication();
      }, err => {
        console.log(err);
        this.loginError = true;
      }
    );
  }

  checkCredentials(credentials) {
    this.authenticationService.authenticate(credentials).subscribe(
      data => {
        this.handleSuccessfulAuthentication();
      }, err => {
        // two factor error
        switch (err.error.error) {
          case 'invalid_grant':
            this.loginError = true;
            break;
          default:
            console.log(err);
            this.serverError = true;
        }
      }
    );
  }

}
